import orderItem from '../../components/order-item/index.vue';
import { mapActions, mapGetters } from 'vuex';
import Empty from '@/components/@general/Empty/index.vue';

export default {
  name: 'current-orders',
  components: {
    Empty,
    orderItem
  },
  data() {
    return {
      ordersList: [],
      currentPage: 1,
      hasNextPage: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'profile/user'
    })
  },
  created() {
    this.fetchInfo();
  },
  methods: {
    ...mapActions({
      fetchCurrentOrders: `profile/GET_CURRENT_ORDERS`
    }),
    fetchInfo() {
      this.loading = true;
      if (this.$route.query.order) {
        this.fetchCurrentOrdersUntilFound({
          page: this.currentPage,
          orderId: this.$route.query.order,
          perPage: 15
        });
      } else {
        this.fetchCurrentOrders({ page: this.currentPage, perPage: 15 }).then((res) => {
          this.ordersList = res.data;
          this.hasNextPage = !!res.links.next;
          this.loading = false;
        });
      }
    },
    fetchCurrentOrdersUntilFound({ page, orderId }) {
      return this.fetchCurrentOrders({ page, perPage: 50 }).then((res) => {
        this.ordersList = this.ordersList ? [...this.ordersList, ...res.data] : res.data;
        this.hasNextPage = !!res.links.next;
        const orderFound = this.ordersList.some((item) => item.id === orderId);

        if (this.hasNextPage && !orderFound) {
          return this.fetchCurrentOrdersUntilFound({ page: page + 1, orderId, perPage: 50 });
        } else {
          this.loading = false;
        }
      });
    },
    loadMore() {
      this.loading = true;
      this.currentPage++;
      this.fetchCurrentOrders({ page: this.currentPage }).then((res) => {
        this.ordersList = [...this.ordersList, ...res.data];
        this.hasNextPage = !!res.links.next;
        this.loading = false;
      });
    }
  }
};
